import { Component, OnInit, Inject, ElementRef, HostListener } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject, concat } from "rxjs";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ViewChild } from "@angular/core";
import { AskForUserInfoService } from "src/app/services/ask-for-user-info.service";
import { MatDialog } from "@angular/material/dialog";
import { AdService } from "src/app/services/ad.service";
declare var jQuery: any;
declare var $: any;
@Component({
  selector: "app-get-user-info",
  templateUrl: "./get-user-info.component.html",
  styleUrls: ["./get-user-info.component.css"],
})
export class GetUserInfoComponent implements OnInit {
  UserInfoForm: FormGroup;
  UsagePermissionData: any;
  errorExist: boolean = false;
  errorMsg: any;
  adShow: boolean;

  showOtherInput: boolean = false;

  availableInterests = [
    { category: 'Building & Refrigeration Sciences', value: '01 - Thermodynamics & Psychrometric' },
    { category: 'Building & Refrigeration Sciences', value: '02 - Heat Transfer and Fluid Flow' },
    { category: 'Building & Refrigeration Sciences', value: '03 - Controls, Instruments, Measurement' },
    { category: 'Building & Refrigeration Sciences', value: '04 - Refrigerants, Lubricants, & Refrigerant Systems' },
    { category: 'Operation & Management', value: '10 - Building Operation & Maintenance' },
    { category: 'Operation & Management', value: '11 - Software' },
    { category: 'Operation & Management', value: '12 - Building Automation' },
    { category: 'Operation & Management', value: '13 - Business Management' },
    { category: 'Design & Applications', value: '20 - Building Design & Load Calculations' },
    { category: 'Design & Applications', value: '21 - Insulation, Fenestration, Building Envelope' },
    { category: 'Design & Applications', value: '22 - Large Building Applications' },
    { category: 'Design & Applications', value: '23 - Residential & Small Building Applications' },
    { category: 'Design & Applications', value: '24 - Healthcare Facility Design' },
    { category: 'Design & Applications', value: '25 - Educational Facility Design' },
    { category: 'Design & Applications', value: '26 - Mission Critical Facility Design' },
    { category: 'Design & Applications', value: '27 - Industrial & Manufacturing Facility Design' },
    { category: 'Design & Applications', value: '28 - Institutional & Government Facility Design' },
    { category: 'Design & Applications', value: '29 - Justice Facility Design' },
    { category: 'Design & Applications', value: '30 - Hotel, Motel & Resort Design' },
    { category: 'Design & Applications', value: '31 - Laboratory Systems & Clean Spaces' },
    { category: 'Design & Applications', value: '32 - Electrical Systems' },
    { category: 'Design & Applications', value: '33 - Duct Design & Room Air Distribution' },
    { category: 'Design & Applications', value: '34 - Commissioning & Testing/Balancing' },
    { category: 'Design & Applications', value: '35 - Seismic and Wind Restraint Design' },
    { category: 'Design & Applications', value: '36 - Fire and Smoke Control' },
    { category: 'Design & Applications', value: '37 - Chemical and Biological Remediation' },
    { category: 'Design & Applications', value: '38 - Industrial and Kitchen Ventilation' },
    { category: 'Design & Applications', value: '39 - Hydronic, Steam & Evaporative Equipment & Systems' },
    { category: 'Design & Applications', value: '40 - Plumbing & Service Water Equipment' },
    { category: 'Design & Applications', value: '41 - Fans, Motors & Forced Air Distribution' },
    { category: 'Design & Applications', value: '42 - Radiant Heating & Cooling' },
    { category: 'Design & Applications', value: '43 - Lighting' },
    { category: 'Indoor Environmental Quality', value: '50 - IAQ Procedures' },
    { category: 'Indoor Environmental Quality', value: '51 - Ventilation Requirements & Infiltration' },
    { category: 'Indoor Environmental Quality', value: '52 - Air Cleaning & Odor Control' },
    { category: 'Indoor Environmental Quality', value: '53 - Moisture Management & Control' },
    { category: 'Indoor Environmental Quality', value: '54 - Humidifying & Dehumidifying Equipment' },
    { category: 'Indoor Environmental Quality', value: '55 - Sound & Vibration' },
    { category: 'Indoor Environmental Quality', value: '56 - Physiology' },
    { category: 'Building Sustainability', value: '60 - Energy Analysis & Resource Utilization' },
    { category: 'Building Sustainability', value: '61 - Building Environmental Impacts & Climate' },
    { category: 'Building Sustainability', value: '62 - Applied Heat Pump & Heat Recovery Systems' },
    { category: 'Building Sustainability', value: '63 - Geothermal Heat Pump & Heat Recovery Applications' },
    { category: 'Building Sustainability', value: '64 - Thermal Storage' },
    { category: 'Building Sustainability', value: '65 - District Heating/Cooling & Cogeneration' },
    { category: 'Building Sustainability', value: '66 - Solar Energy Applications' },
    { category: 'Refrigeration', value: '70 - Food & Beverage Refrigeration' },
    { category: 'Refrigeration', value: '71 - Industrial & Transport Refrigeration' },
    { category: 'Refrigeration', value: '72 - Low Temperature Refrigeration' },
    { category: 'Refrigeration', value: '73 - Refrigeration Load Calculations' },
    { category: 'Refrigeration', value: '74 - Refrigeration & AC Equipment Design & Performance' },
    // ... add more interests as needed
  ];
  showProfessionalInput: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private userinfService: AskForUserInfoService,
    private dialogRef: MatDialogRef<GetUserInfoComponent>,
    private adService: AdService
  ) {}

  ngOnInit() {
   this.loadInitialForm();
    //  console.log(this.data);
  }
  onFormSubmit(UserInfoForm: any) {
    if (this.UserInfoForm.status == "INVALID") {
      return false;
    }
    this.userinfService.UserInfoData(this.UserInfoForm.value).subscribe(
      (res: any) => {
        if (res.CheckCon) {
          // Request successfully added
          const uuid = res.uuid;
          sessionStorage.setItem('uuid', uuid);
          
          // Store the form data in localStorage
          const formData = this.UserInfoForm.value;
          localStorage.setItem('userInfoData', JSON.stringify(formData));
          
          this.dialogRef.close({
            userData: this.UserInfoForm.value,
          });
        } else {
          this.errorExist = true;
          this.errorMsg = res.message;
        }
      },
      (err: any) => {
        console.log(err);
      },
      () => {
        console.log('Request completed');
      }
    );
  }  

   // Function to get unique categories from availableInterests
   get interestCategories() {
    return Array.from(new Set(this.availableInterests.map((interest) => interest.category)));
  }

  // Function to get technical interests based on the selected category
  getInterestsByCategory(category: string) {
    return this.availableInterests.filter((interest) => interest.category === category);
  }

  onProfessionalTitle(value: string) {
    this.showProfessionalInput = value === ''
  }

  onPrincipleActivityChange(value: string) {
    // Set showOtherInput to true only when "Other (Please Specify)" is selected
    this.showOtherInput = value === '';
  }
  async loadInitialForm() {
    var lines;

    //localhost only. uncomment below line of codes for prod.
    await $.get("https://www.cloudflare.com/cdn-cgi/trace", function (data) {
      //console.log(data);
      lines = data.split("\n");
    });
    //console.log(lines);
    this.UserInfoForm = this.formBuilder.group({
      refPublicationGroupId: [this.data[1].idPublicationGroup],
      UsageType: ["view"],
        //localhost only. uncomment below line of codes for prod.
      browser: [lines[5].split("=")[1]],
      ipAddress: [lines[2].split("=")[1]],
      accessType: [null],
      customerId: [this.data[3].idCustomer],
      RefGroupId: [this.data[0].idGroup],
      idPublication: [this.data[2].idPublication],
      publicationTitle: [this.data[2].publicationName],
      emailaddress: [null],
      phoneno: [null],
      passcode: [null],
      organization: [null],
      fullName: [""],
      professionalTitle: [null],
      principleActivity: ['None', Validators.required],
      technicalInterests: [[]],
      specifyProducts: [null],
      receiveInformation: [null],
      productAnnouncement: [null]    
    });
  }
}
