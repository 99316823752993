import {
  Component,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from "@angular/core";
import license from "./license-key";
import * as UIExtension from "../../../foxit-lib/UIExtension.full.js";
import * as preloadJrWorker from "../../../foxit-lib/preload-jr-worker.js";
import * as $ from "jquery";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Subject, concat } from "rxjs";
import { CustomerService } from "src/app/services/customer.service";
import { GroupService } from "src/app/services/group.service";
import { PublicationService } from "src/app/services/publication.service";
import { PublicationGroupService } from "src/app/services/publication-group.service";
import { PermissionUsageService } from "src/app/services/permission-usage.service";
import { TrafficUsageService } from "src/app/services/traffic-usage.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DeviceDetectorService } from "ngx-device-detector";
import { Title } from "@angular/platform-browser";
import { MatDialog } from "@angular/material/dialog";
import { GetUserInfoComponent } from "../get-user-info/get-user-info.component";
import { GdprConsentComponent } from "../gdpr-consent/gdpr-consent.component";
import { environment } from "src/environments/environment";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { HttpClient } from "@angular/common/http";
import * as CryptoJS from 'crypto-js';

@Component({
  selector: "app-foxitpdfviewer",
  template: "",
  styleUrls: ["./pdfviewer.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class PDFViewerComponent implements OnInit {
  pdfui: any;
  @Input() itemURL = "";
  destroy$: Subject<boolean> = new Subject<boolean>();
  customer_code: string;
  GDPRcheck: boolean = false;
  collection_code: string;
  publication_code: string;
  newdata: any;
  customers: Array<any>;
  singleCustomer: any;
  groups: Array<any>;
  singleGroup: any;
  userDataObj: any;
  publications: Array<any>;
  singlePublication: any;
  permissions: any;
  publicationGroups: Array<any>;
  singlePublicationGroup: any;
  viewCounts: any;
  sanitizeurl: any;
  pdfBlobData: any;
  printCounts: number = 0;
  expiry: any;
  ipAddress: string;
  BrowserInfo: string;
  lines: any;
  pdfURL: string;
  GDPRConsent: any = {};
  popupFlag: string;
  GDPRFlag: string;
  navigationMessage: NavigationExtras = {
    state: {
      enableHTML: false,
      logoPath: "",
      primaryColor: "",
      data: "Sorry the page you are looking for cannot be found. Please make sure you have provided the correct URL.",
    },
  };
  countryName: string;
  GDPRCountryList: Array<string> = [
    "AT",
    "BE",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
  ];
  userInfo: any = {
    fullName: "",
    email: "",
    phoneNumber: "",
    organization: "",
  };
  @Output() userData = new EventEmitter<any>();
  buyURL: string;
  @ViewChild("adhtmlsnippet", { static: false }) container: ElementRef;
  constructor(
    private element: ElementRef,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private groupsService: GroupService,
    private publicationService: PublicationService,
    private publicationGroupService: PublicationGroupService,
    private permissionUsageService: PermissionUsageService,
    private trafficUsageService: TrafficUsageService,
    private _snackBar: MatSnackBar,
    private deviceService: DeviceDetectorService,
    private titleService: Title,
    private _router: Router,
    public dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private http: HttpClient
  ) {
    $.get("https://www.cloudflare.com/cdn-cgi/trace", (data) => {
      //console.log(data);
      this.lines = data.split("\n");
      this.countryName = "GR";
      //this.countryName = this.lines[8].split("=")[1];
      this.ipAddress = this.lines[2].split("=")[1];
      this.BrowserInfo = this.lines[5].split("=")[1];
    });
  }

  async ngOnInit() {
    //console.log("here");

    //subscribing to the URL
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.customer_code = params.customer_code;
      //getting customer_code, collection_code and publication_code from the URL
      var subdomain = window.location.host.split(".")[1]
        ? window.location.host.split(".")[0]
        : false;
      if (subdomain != false) {
        if (subdomain.indexOf("-") > -1) {
          this.customer_code = subdomain.split("-")[1];
        } else {
          this.customer_code = subdomain;
        }
      }
      this.customer_code = this.customer_code;
      this.collection_code = params.collection_code
        ? params.collection_code.toUpperCase()
        : null;
      this.publication_code = params.publication_code
        ? params.publication_code.toUpperCase()
        : null;

      let pdfdata = {
        customer_code: this.customer_code,
        collection_code: this.collection_code,
        publication_code: this.publication_code,
        referrer: environment.fileReferrer,
      };
      this.customerService
        .PublicationPdfViewer(pdfdata)
        .subscribe((res: any) => {
          //console.log(res);
          const openpdfviewerData = this.decryptData(res.data, res._id);    
          if (!openpdfviewerData || openpdfviewerData.length === 0) {
            console.error('Error: Unable to decrypt data');
            return;
          }
          // console.log(openpdfviewerData[0].customerPermssiondetail);
          if (res.showerror) {
            this.navigationMessage.state.logoPath = openpdfviewerData[0].logoPath;
            this.navigationMessage.state.primaryColor =
            openpdfviewerData[0].primaryColor;
            this.navigationMessage.state.data = res.message;
            this.navigationMessage.state.enableHTML = true;
            this._router.navigate(["/message"], this.navigationMessage);
          } else {
            this.singlePublicationGroup = Object.assign(
              {},
              openpdfviewerData[0].singlepublicationgroup
            );
            this.singleGroup = openpdfviewerData[0].singlegroup;
            this.printCounts = this.singlePublicationGroup.printlimit;
            this.singlePublication = openpdfviewerData[0].singlePublication;
            this.singleCustomer = openpdfviewerData[0].singleCustomer;
            this.permissions = openpdfviewerData[0].customerPermssiondetail;
            this.pdfURL =
              environment.storageURL +
              "/enc-docs/" +
              this.singleCustomer.idCustomer +
              "/" +
              this.singleGroup.idGroup +
              "/ENC_" +
              this.singlePublication.idPublication +
              ".pdf";
            //console.log(this.permissions);
            if (sessionStorage.getItem("userData") != null) {
              this.userDataObj = JSON.parse(sessionStorage.getItem("userData"));
              this.userDataObj = this.userDataObj.userData;
              this.userDataObj.showpopup
                ? (this.popupFlag = atob(this.userDataObj.showpopup))
                : (this.popupFlag = "false");
              this.userDataObj.showGDPR
                ? (this.GDPRFlag = atob(this.userDataObj.showGDPR))
                : (this.GDPRFlag = "notShow");
              //  console.log(atob(this.userDataObj.showpopup));
            } else {
              this.popupFlag = "false";
              this.GDPRFlag = "notShow";
            }

            if (
              this.permissions.GDPR &&
              this.singleGroup.askForGDPRConsent &&
              this.GDPRFlag != "GDPRConsent" &&
              this.GDPRCountryList.indexOf(this.countryName) !== -1
            ) {
              this.openAskForGDPRConsent();
            } else {
              this.checkUserInfo();
            }
          }
        });
    });

    this.publicationService.getClickCall().subscribe((message) => {
      //console.log(this.singlePublication.publicationName);
      this.buyURL = message.text;
      //console.log(this.singleCustomer.idCustomer);
      this.addUsageHistory(
        this.singleCustomer.idCustomer,
        this.singleGroup.idGroup,
        this.singlePublication.idPublication,
        this.singlePublication.publicationName,
        this.singlePublicationGroup,
        "buynow",
        "allow",
        this.userInfo,
        true,
        this.buyURL
      );
    });
  }
  checkUserInfo() {
    if (
      ((this.permissions.userInformation && this.singleGroup.askForUserInfo) ||
        (this.singleGroup.askForPassCode && this.permissions.passcode)) &&
      this.itemURL != "directdownloadurl" &&
      this.popupFlag != "true"
    ) {
      this.openAskForUserInfoDialog();
    } else {
      if (
        this.permissions.ads &&
        this.singleGroup.askForAds &&
        this.itemURL != "directdownloadurl"
      ) {
        // setTimeout(() => {
        //   this.showAd();
        // }, 4000);
      }
      if (!this.GDPRcheck) {
        this.renderBothPDF();
      }
    }
  }
  printClick() {
    const printbtn = <HTMLElement>(
      document.querySelector(".fv__icon-toolbar-print")
    );
    if (printbtn) {
      printbtn.addEventListener("click", () => {
        this.addUsageHistory(
          this.singleCustomer.idCustomer,
          this.singleGroup.idGroup,
          this.singlePublication.idPublication,
          this.singlePublication.publicationName,
          this.singlePublicationGroup,
          "print",
          "allow",
          this.userInfo
        );
      });
    }
  }
  showAd() {
    if (
      this.permissions.ads &&
      this.singleGroup.askForAds &&
      this.itemURL != "directdownloadurl"
    ) {
      let scripts = document.querySelector(".adSnippetimg script");
      if (scripts) {
        eval(scripts.innerHTML);
        setTimeout(() => {
          $(".slider").show();
          $(".ad-container").animate({ bottom: "0px" }, 200);
        }, 2000);
        setTimeout(() => {
          const iframe = document.querySelector("iframe");
          const iframebody = document
            .querySelector("iframe")
            .contentWindow.document.querySelector("body");
          const iframeimage = document
            .querySelector("iframe")
            .contentWindow.document.querySelector("img");
          const newtabLink = document
            .querySelector("iframe")
            .contentWindow.document.querySelector("a");
          newtabLink.setAttribute("target", "_blank");
          iframebody.style.cssText += "text-align:center";
          if ($(window).width() < 567) {
            iframeimage.style.cssText += "width:auto;max-height:200px";
          } else {
            iframeimage.style.cssText += "width:auto;max-height:300px";
          }
        }, 2000);
      } else {
        setTimeout(() => {
          $(".slider").show();
          $(".ad-container").animate({ bottom: "0px" }, 200);
        }, 5000);
      }
    }
  }
  openAskForUserInfoDialog(): void {
    const dialogRef = this.dialog.open(GetUserInfoComponent, {
      width: "600px",
      data: [
        this.singleGroup,
        this.singlePublicationGroup,
        this.singlePublication,
        this.singleCustomer,
        this.permissions,
      ],
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.userData.emit(result);
      // if (this.singleGroup.askForAds && this.itemURL != "directdownloadurl") {
      //   setTimeout(() => {
      //     this.showAd();
      //   }, 5000);
      // }
      this.renderBothPDF();
    });
  }
  renderBothPDF() {
    const isMobile = this.deviceService.isMobile();
    if (isMobile) {
      this.renderPDFMobile();
    } else {
      this.renderPDF();
    }
  }
  openAskForGDPRConsent() {
    if (!this.singleGroup.askForPassCode && !this.singleGroup.askForUserInfo) {
      this.renderBothPDF();
      this.GDPRcheck = true;
    }
    const bottomSheetRef = this.bottomSheet.open(GdprConsentComponent, {
      disableClose: true,
      panelClass: "gdprpanel-sheet",
    });
    bottomSheetRef.afterDismissed().subscribe((result) => {
      this.GDPRConsent.userData = {};
      // console.log(this.GDPRConsent);
      this.userData.emit(this.GDPRConsent);
      if (result) {
        this.checkUserInfo();
      }
      // console.log(this.GDPRConsent);
    });
  }
  renderPDF() {
    //console.log(this.userInfo);
    var readyWorker = preloadJrWorker({
      workerPath: "/foxit-lib/",
      enginePath: "/foxit-lib/jr-engine/gsdk",
      licenseSN: license.licenseSN,
      licenseKey: license.licenseKey,
    });
    var PDFUI = UIExtension.PDFUI;
    var Events = UIExtension.PDFViewCtrl.Events;
    var Log = UIExtension.PDFViewCtrl.Log;
    Log.setLogLevel(Log.LEVELS.LEVEL_ERROR);
    var selectionTool = {};
    var menuSelectionTool = {};
    if (!this.singleGroup.rights.allowCopy) {
      selectionTool = {
        target: "selection-text-annotation",
        action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
      };
      menuSelectionTool = {
        target: "fv--contextmenu-item-select-tool",
        action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
      };
    } else {
      selectionTool = {
        target: "selection-text-annotation",
        action: "",
      };
      menuSelectionTool = {
        target: "fv--contextmenu-item-select-tool",
        action: "",
      };
    }
    //console.log(this.printCounts);
    var printTool = ["/foxit-lib/uix-addons/thumbnail"];
    if (
      this.printCounts < this.singleGroup.rights.allowedPrintCount ||
      this.singleGroup.rights.allowedPrintCount == -1
    ) {
      printTool.push("/foxit-lib/uix-addons/print");
      printTool.concat(
        UIExtension.PDFViewCtrl.DeviceInfo.isMobile
          ? []
          : "/foxit-lib/uix-addons/text-object"
      );
    } else {
    }
    var pdfui = new PDFUI({
      viewerOptions: {
        libPath: "/foxit-lib",
        jr: {
          readyWorker: readyWorker,
        },
      },
      renderTo: this.element.nativeElement,
      appearance: UIExtension.appearances.adaptive,
      fragments: [
        selectionTool,
        menuSelectionTool,
        {
          target: "snapshot-button",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
        {
          target: "home-tab-group-magnifier",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
        {
          target: "open-file-button-list",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
        {
          target: "download-file-button",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
        {
          target: "comment-tab",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
        {
          target: "protect-tab",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
        {
          target: "edit-tab",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
        {
          target: "comment-list-sidebar-panel",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
        //{
        //    target: 'sidebar-thumbnail',
        //    action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE
        //},
        {
          target: "sidebar-layers",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
        {
          target: "home-tab-group-marquee",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
      ],
      addons: printTool,
    });
    pdfui.addUIEventListener("fullscreenchange", function (isFullscreen) {
      if (isFullscreen) {
        document.body.classList.add("fv__pdfui-fullscreen-mode");
      } else {
        document.body.classList.remove("fv__pdfui-fullscreen-mode");
      }
    });

    function openLoadingLayer() {
      return pdfui.loading();
    }
    var loadingComponentPromise = openLoadingLayer();

    pdfui.addViewerEventListener(Events.beforeOpenFile, function () {
      if (loadingComponentPromise) {
        loadingComponentPromise = loadingComponentPromise
          .then(function (component) {
            component.close();
          })
          .then(openLoadingLayer);
      } else {
        loadingComponentPromise = openLoadingLayer();
      }
    });
    pdfui.addViewerEventListener(Events.openFileSuccess, function () {
      loadingComponentPromise.then(function (component) {
        component.close();
      });
    });
    pdfui.addViewerEventListener(Events.openFileFailed, function () {
      loadingComponentPromise.then(function (component) {
        component.close();
      });
    });
    
    const vPdfUrl = this.pdfURL + environment.versionURL;
    // //var url = "https://api.iwrapper.com/docs/pasha/SALARY_SURVEY_2021.pdf";
    this.http
      .get(vPdfUrl, {
        responseType: "blob",
      })
      .subscribe((resp: any) => {
         
        let pdfdata = {
          customer_code: this.customer_code,
          collection_code: this.collection_code,
          publication_code: this.publication_code,
          referrer: environment.fileReferrer,
        };
        

        this.customerService
        .PublicationOpenPassword(pdfdata)
        .subscribe(
          (res: any) => {
            
            if (res) {

              const encodedPassword = res._guid;
              const salt = res._id
              
              const decryptedData = CryptoJS.AES.decrypt(encodedPassword, salt).toString(CryptoJS.enc.Utf8);
              const orgText = decryptedData.replace(/"/g, '');

              pdfui
                .openPDFByFile(resp, {
                  password: orgText,
                })
                .then((resp) => {
                  this.showAd();
                  this.printClick();
                });
              
              // Assuming 'pdfUrl' is a property in the response that contains the URL of the PDF
              // window.open(res.pdfUrl, '_blank');
            } else {
              console.error('PDF URL not found in the response');
            }
          },
          (error) => {
            console.error('Error fetching PDF:', error);
          }
        );

        // const encodedPassword = this.singlePublicationGroup.openPassword;

        // const parts = encodedPassword.split('$');
        // const splitedPassword = parts.length > 1 ? parts[1] : '';

        // const originalPassword = this.fromBase64(splitedPassword);
        // //console.log("originalPassword", originalPassword);
        // // console.log(resp);
        // pdfui
        //   .openPDFByFile(resp, {
        //     password: originalPassword,
        //   })
        //   .then((resp) => {
        //     this.showAd();
        //     this.printClick();
        //   });
      });

    // pdfui
    //   .openPDFByHttpRangeRequest(
    //     {
    //       range: {
    //         url:
    //           "/enc-docs/" +
    //           this.singleCustomer.idCustomer +
    //           "/" +
    //           this.singleGroup.idGroup +
    //           "/ENC_" +
    //           this.singlePublication.idPublication +
    //           ".pdf",
    //         //localhost only
    //         //url: "/assets/docs/dummy.pdf",
    //       },
    //     },
    //     {
    //       fileName: this.singlePublication.name,
    //       password: this.singlePublicationGroup.openPassword,
    //     }
    //   )
    //   .then((resp) => {
    //     this.showAd();
    //     this.printClick();
    //   });

    window.onresize = function () {
      pdfui.redraw().catch(function () {});
    };

    $(document).bind("keyup keydown", function (e) {
      if (e.ctrlKey && e.keyCode == 80) {
        return false;
      }
    });

    document.body.addEventListener("keydown", (event) => {
      if (event.ctrlKey && "ocs".indexOf(event.key) !== -1) {
        event.preventDefault();
      }
    });

    // this.addTrafficUsage(this.singlePublication, this.trafficUsageService);
    if (
      !this.singleGroup.askForUserInfo &&
      !this.singleGroup.askForPassCode &&
      this.itemURL != "directdownloadurl"
    ) {
      //console.log("here");
      this.addUsageHistory(
        this.singleCustomer.idCustomer,
        this.singleGroup.idGroup,
        this.singlePublication.idPublication,
        this.singlePublication.publicationName,
        this.singlePublicationGroup,
        "view",
        "allow",
        this.userInfo
      );
    }
  }
  //renders the PDF file with restrictions in mobile
  renderPDFMobile() {
    //console.log('in mobile view');
    var readyWorker = preloadJrWorker({
      workerPath: "/foxit-lib/",
      enginePath: "/foxit-lib/jr-engine/gsdk",
      licenseSN: license.licenseSN,
      licenseKey: license.licenseKey,
    });
    var PDFUI = UIExtension.PDFUI;
    var Events = UIExtension.PDFViewCtrl.Events;
    var Log = UIExtension.PDFViewCtrl.Log;
    Log.setLogLevel(Log.LEVELS.LEVEL_ERROR);
    var selectionTool = {};
    if (!this.singleGroup.rights.allowCopy) {
      selectionTool = {
        target: "fv--text-contextmenu",
        action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
      };
    } else {
      selectionTool = {
        target: "fv--text-contextmenu",
        action: "",
      };
    }
    /* var dropDownIcon = {
        target: 'fv--mobile-topbar-tools-dropdown',
        action: ''
    }; */
    var printTool = ["/foxit-lib/uix-addons/thumbnail"];
    if (
      this.printCounts <= this.singleGroup.rights.allowedPrintCount ||
      this.singleGroup.rights.allowedPrintCount == -1
    ) {
      printTool.push("/foxit-lib/uix-addons/print");
      printTool.concat(
        UIExtension.PDFViewCtrl.DeviceInfo.isMobile
          ? []
          : "/foxit-lib/uix-addons/text-object"
      );
    } else {
      /* dropDownIcon = {
            target: 'fv--mobile-topbar-tools-dropdown',
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE
        }; */
    }
    var pdfui = new PDFUI({
      viewerOptions: {
        libPath: "/foxit-lib",
        jr: {
          readyWorker: readyWorker,
        },
      },
      renderTo: this.element.nativeElement,
      appearance: UIExtension.appearances.adaptive,
      fragments: [
        selectionTool,
        /* dropDownIcon, */
        {
          target: "stamp-drop-down-ui",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
        {
          target: "fv--open-localfile-button",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
        {
          target: "fv--open-fromurl-button",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
        {
          target: "download-file-button",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
        {
          target: "fv-mobile-attachment-dropdown",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
        {
          target: "fv--mobile-header-main",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
        {
          target: "fv--mobile-toolbar-tabs",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
        {
          target: "fv--mobile-header-left",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        },
      ],
      addons: printTool,
    });
    pdfui.addUIEventListener("fullscreenchange", function (isFullscreen) {
      if (isFullscreen) {
        document.body.classList.add("fv__pdfui-fullscreen-mode");
      } else {
        document.body.classList.remove("fv__pdfui-fullscreen-mode");
      }
    });

    function openLoadingLayer() {
      return pdfui.loading();
    }
    var loadingComponentPromise = openLoadingLayer();

    pdfui.addViewerEventListener(Events.beforeOpenFile, function () {
      if (loadingComponentPromise) {
        loadingComponentPromise = loadingComponentPromise
          .then(function (component) {
            component.close();
          })
          .then(openLoadingLayer);
      } else {
        loadingComponentPromise = openLoadingLayer();
      }
    });
    pdfui.addViewerEventListener(Events.openFileSuccess, function () {
      loadingComponentPromise.then(function (component) {
        component.close();
      });
    });
    pdfui.addViewerEventListener(Events.openFileFailed, function () {
      loadingComponentPromise.then(function (component) {
        component.close();
      });
    });
    const vPdfUrl = this.pdfURL + environment.versionURL;

    this.http
      .get(vPdfUrl, {
        responseType: "blob",
      })
      .subscribe((resp: any) => {
         
         let pdfdata = {
          customer_code: this.customer_code,
          collection_code: this.collection_code,
          publication_code: this.publication_code,
          referrer: environment.fileReferrer,
        };

         


        this.customerService
        .PublicationOpenPassword(pdfdata)
        .subscribe(
          (res: any) => {
           
            if (res) {

              const encodedPassword = res._guid;
         
              const parts = encodedPassword.split('$');
              const splitedPassword = parts.length > 1 ? parts[1] : '';

              const originalPassword = this.fromBase64(splitedPassword);
              // console.log("originalPassword", originalPassword);
              // console.log("splitedPassword", splitedPassword);
              // console.log(resp);

              pdfui
                .openPDFByFile(resp, {
                  password: originalPassword,
                })
                .then((resp) => {
                  this.showAd();
                  this.printClick();
                });
              
              // Assuming 'pdfUrl' is a property in the response that contains the URL of the PDF
              // window.open(res.pdfUrl, '_blank');
            } else {
              console.error('PDF URL not found in the response');
            }
          },
          (error) => {
            console.error('Error fetching PDF:', error);
          }
        );

        // const encodedPassword = this.singlePublicationGroup.openPassword;

        // const parts = encodedPassword.split('$');
        // const splitedPassword = parts.length > 1 ? parts[1] : '';

        // const originalPassword = this.fromBase64(splitedPassword);
        // // console.log("originalPassword 123", originalPassword);
        // // console.log(resp);
        // pdfui
        //   .openPDFByFile(resp, {
        //     password: originalPassword,
        //   })
        //   .then((resp) => {
        //     this.showAd();
        //     this.printClick();
        //   });
      });
    // pdfui
    //   .openPDFByHttpRangeRequest(
    //     {
    //       range: {
    //         url:
    //           "/enc-docs/" +
    //           this.singleCustomer.idCustomer +
    //           "/" +
    //           this.singleGroup.idGroup +
    //           "/ENC_" +
    //           this.singlePublication.idPublication +
    //           ".pdf",
    //         //url: "assets/docs/dummy.pdf",
    //       },
    //     },
    //     {
    //       fileName: this.singlePublication.name,
    //       password: this.singlePublicationGroup.openPassword,
    //     }
    //   )
    //   .then((resp) => {
    //     this.showAd();
    //     this.printClick();
    //   });

    window.onresize = function () {
      pdfui.redraw().catch(function () {});
    };

    $(document).bind("keyup keydown", function (e) {
      if (e.ctrlKey && e.keyCode == 80) {
        return false;
      }
    });

    document.body.addEventListener("keydown", (event) => {
      if (event.ctrlKey && "ocs".indexOf(event.key) !== -1) {
        event.preventDefault();
      }
    });

    //this.addTrafficUsage(this.singlePublication, this.trafficUsageService);
    if (
      !this.singleGroup.askForUserInfo &&
      !this.singleGroup.askForPassCode &&
      this.itemURL != "directdownloadurl"
    ) {
      this.addUsageHistory(
        this.singleCustomer.idCustomer,
        this.singleGroup.idGroup,
        this.singlePublication.idPublication,
        this.singlePublication.publicationName,
        this.singlePublicationGroup,
        "view",
        "allow",
        this.userInfo
      );
    }
    setTimeout(() => {
      const printbtn = <HTMLElement>(
        document.querySelector(".fv__icon-toolbar-print")
      );
      if (printbtn) {
        printbtn.addEventListener("click", () => {
          this.addUsageHistory(
            this.singleCustomer.idCustomer,
            this.singleGroup.idGroup,
            this.singlePublication.idPublication,
            this.singlePublication.publicationName,
            this.singlePublicationGroup,
            "print",
            "allow",
            this.userInfo
          );
        });
      }
    }, 600);
  }

  async addUsageHistory(
    idCustomer,
    idGroup,
    idPublication,
    publicationTitle,
    publicationGroup,
    usageType,
    accessType,
    userInfo,
    doRedirect = false,
    buyURL = ""
  ) {
    if (!this.ipAddress) {
      await $.get("https://www.cloudflare.com/cdn-cgi/trace", (data) => {
        //console.log(data);
        this.lines = data.split("\n");
        this.countryName = "GR";
        //this.countryName = this.lines[8].split("=")[1];
        this.ipAddress = this.lines[2].split("=")[1];
        this.BrowserInfo = this.lines[5].split("=")[1];
      });
    }
    let windowReference;
    if (doRedirect && buyURL != "" && buyURL != "iWrapperShoppingCart") {
      windowReference = window.open();
    }
    this.newdata = {
      _refPublicationGroupId: publicationGroup.idPublicationGroup,
      usageType: usageType,
      idCustomer: idCustomer,
      idGroup: idGroup,
      idPublication: idPublication,
      publicationTitle: publicationTitle,
      browser: this.BrowserInfo,
      ipAddress: this.ipAddress,
      fullName: userInfo.fullName,
      email: userInfo.email,
      phoneNumber: userInfo.phoneNumber,
      accessType: accessType,
    };
    this.permissionUsageService.addUsageHistory(this.newdata).subscribe(
      (res) => {
        if (doRedirect && buyURL != "" && buyURL != "iWrapperShoppingCart") {
          //window.open(buyURL, '_blank');
          windowReference.location.assign(buyURL);
        }
      },
      (err) => {}
    );
  }

  // decodeBase64(base64: string): string {
  //   return atob(base64);
  // }
  // fromBase64(input: string): string {
  //   const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   let str = input.replace(/[^A-Za-z0-9+/=]/g, ''); // Remove any non-Base64 characters
  //   let output = '';
  //   let buffer = 0;
  //   let bits = 0;

  //   for (let i = 0; i < str.length; i++) {
  //     const char = str.charAt(i);
  //     const value = chars.indexOf(char);

  //     if (value === -1) {
  //       continue; // Skip invalid characters
  //     }

  //     buffer = (buffer << 6) | value;
  //     bits += 6;

  //     if (bits >= 8) {
  //       bits -= 8;
  //       output += String.fromCharCode((buffer >> bits) & 0xFF);
  //     }
  //   }

  //   return output;
  // }
  fromBase64(input: string): string {
    // try {
    //   return decodeURIComponent(atob(input).split('').map((char) => {
    //     return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
    //   }).join(''));
    // } catch (e) {
    //   console.error('Base64 decoding failed:', e);
    //   return '';
    // }
    return atob(input);
  }

  decryptData(cipherText: string, salt: string): any {
    try {
      // Decrypt the cipherText using the salt
      const bytes = CryptoJS.AES.decrypt(cipherText, salt);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      
      // Parse the decrypted JSON string back into an object
      return JSON.parse(decryptedData);
    } catch (e) {
      console.error('Error decrypting data:', e);
      return null;
    }
  }

}
