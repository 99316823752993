import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { CustomerService } from "src/app/services/customer.service";
import { PublicationService } from "src/app/services/publication.service";
import { Title } from "@angular/platform-browser";
import { PublicationGroupService } from "src/app/services/publication-group.service";
import { PermissionUsageService } from "src/app/services/permission-usage.service";
import { MatDialog } from "@angular/material/dialog";
import { PoweredByIwrapperComponent } from "../powered-by-iwrapper/powered-by-iwrapper.component";
import { PaymentGatewayPopupComponent } from "../payment-gateway-popup/payment-gateway-popup.component";
import { DownloadPdfComponent } from "../download-pdf/download-pdf.component";
import { PublicationModel } from "../../models/publicationModel";
import { environment } from "src/environments/environment";
import { NoSanitizePipe } from "../show-ads/NoSanitizePipe";
import * as $ from "jquery";
import * as CryptoJS from 'crypto-js';
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  providers: [NoSanitizePipe],
})
export class HomeComponent implements OnInit {
  baseURL = environment.baseapiURL;
  storageURL = environment.storageURL;
  logoPath: string = "/assets/logo.png";
  primaryColor: string = "#005195";
  secondaryColor: string = "#ebd0ec";
  buyNowUrl: string = "";
  brandingCaption: string = "";
  customerName: string = "";
  scrHeight: any;
  sanitizeurl: any;
  scrWidth: any;
  showSpinner: boolean = true;
  adminshoppingcartEnabled: boolean;
  publicationDetail: PublicationModel;
  publicationGroupId: any;
  getUserInfo: boolean = false;
  collection_code: any;
  UserinfoPopup: string;
  isShoppingCartEnabled: boolean = false;
  isDemoCollection: boolean = false;
  askForDownload: boolean = false;
  adminUserInfo: boolean;
  singlePublicationGroup: any;
  headerClass: string;
  filePath: string;
  askForGDPRConsent: boolean;
  singleGroup: any;
  domainName: string;
  customer_code: string;
  permissions: string;
  mobileHeightConatiner: string;
  publication_code: string;
  showToolbar: boolean = true;
  askForUserInfo: boolean;
  userDatainfo: any;
  base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  userDataObj: any;
  noOfPages: number;
  navigateurlcheck: any = "";
  navigationMessage: NavigationExtras = {
    state: {
      enableHTML: false,
      logoPath: "",
      primaryColor: "",
      data: "Sorry the page you are looking for cannot be found. Please make sure you have provided the correct URL.",
    },
  };
  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private customerService: CustomerService,
    private publicationService: PublicationService,
    private titleService: Title,
    private publicationGroupService: PublicationGroupService,
    private permissionUsageService: PermissionUsageService,
    public dialog: MatDialog,
    private _nosanitizePipe: NoSanitizePipe
  ) {
    this.publicationDetail = new PublicationModel();
    if (this._router.getCurrentNavigation().extras.hasOwnProperty("state")) {
      this.navigateurlcheck =
        this._router.getCurrentNavigation().extras.state.data;
      //console.log(this.navigateurlcheck);
      if (this.navigateurlcheck == "directdownloadurl") {
        this.getUserInfo = true;
      }
    } else {
      //sessionStorage.removeItem("userData");
    }
  }

  beforeUnloadHandler(event) {}
  ngOnInit() {
    // window.addEventListener("beforeunload", (event) => {
    //   console.log(event);
    //   sessionStorage.removeItem("userData");
    // });
    $(document).keydown(function (event) {
      if (event.keyCode == 123) {
        // Prevent F12
        return false;
      } else if (
        (event.ctrlKey && event.shiftKey && event.keyCode == 73) ||
        (event.ctrlKey && event.shiftKey && event.keyCode == 67) ||
        (event.ctrlKey && event.shiftKey && event.keyCode == 74)
      ) {
        // Prevent Ctrl+Shift+I
        return false;
      }
    });
    $(document).on("contextmenu", function (e) {
      e.preventDefault();
    });
    this.route.params.subscribe((params) => {
      //console.log(window.location.host);
      this.customer_code = params.customer_code;
      var subdomain = window.location.host.split(".")[1]
        ? window.location.host.split(".")[0]
        : false;
      if (subdomain != false) {
        if (subdomain.indexOf("-") > -1) {
          this.customer_code = subdomain.split("-")[1];
        } else {
          this.customer_code = subdomain;
        }
      }
      this.publication_code = params.publication_code
        ? params.publication_code.toUpperCase()
        : null;
      //console.log(this.publication_code);
      this.collection_code = params.collection_code
        ? params.collection_code.toUpperCase()
        : null;
      //localhost only
      //this.collection_code  = "BROWSER_ACCESS_PAYMENT"
      // if(this.collection_code=="BROWSER_ACCESS_PAYMENT"){
      //   this.isDemoCollection = true;
      // }
      this.headerClass = "mobile-toolbar-show";
      let data = {
        idCustomer: this.customer_code,
        idPublication: this.publication_code,
        idCollection: this.collection_code,
        //for localhost only
        // idCustomer: "demo",
        //idCollection: "BROWSER_ACCESS_PAYMENT",
        //idPublication: "ACCESS_PAYMENT_PUBLICATION",
      };
      this.customerService.getPublicationDetail(data).subscribe(
        (res: any) => {
          if (res.error) {
            const publicationpdfviewerData = this.decryptData(res.data, res._id);
            

            this.navigationMessage.state.logoPath = publicationpdfviewerData[0].logoPath;
            this.navigationMessage.state.primaryColor =
              res.data[0].primaryColor;
            this.navigationMessage.state.data = publicationpdfviewerData.showmessage;
            this.navigationMessage.state.enableHTML = true;
            this._router.navigate(["/message"], this.navigationMessage);
          } else {

            const publicationpdfviewerData = this.decryptData(res.data, res._id);
            // console.log(res);
            this.titleService.setTitle(
              publicationpdfviewerData[0].customerName + " - iWrapper"
            );
            this.singleGroup = publicationpdfviewerData[3];
            // console.log(this.singleGroup);
            //console.log(res.data);
            this.logoPath = publicationpdfviewerData[0].logoPath;
            this.primaryColor = publicationpdfviewerData[0].primaryColor;
            this.secondaryColor = publicationpdfviewerData[0].secondaryColor;
            this.brandingCaption = publicationpdfviewerData[0].brandingCaption;
            this.customerName = publicationpdfviewerData[0].customerName;
            this.domainName = publicationpdfviewerData[0].domain;
            this.showToolbar = publicationpdfviewerData[0].showToolbar;
            this.buyNowUrl = publicationpdfviewerData[1].buyUrl;
            this.noOfPages = publicationpdfviewerData[1].noOfPages;
            this.filePath = publicationpdfviewerData[1].filePath;
            this.adminshoppingcartEnabled = publicationpdfviewerData[4].monetization;
            this.adminUserInfo = publicationpdfviewerData[4].userInformation;
            this.permissions = publicationpdfviewerData[4].ads;
            // console.log(this.adminshoppingcartEnabled);
            this.isShoppingCartEnabled = publicationpdfviewerData[2].isMonitizationEnabled;
            //console.log(this.isShoppingCartEnabled);
            this.askForDownload = publicationpdfviewerData[3].askForDownload;
            this.askForUserInfo = publicationpdfviewerData[3].askForUserInfo;
            this.UserinfoPopup = publicationpdfviewerData[3].UserinfoPopup;
            if (publicationpdfviewerData[3].askForUserInfo) {
              if (sessionStorage.getItem("userData") != null) {
                this.userDataObj = JSON.parse(
                  sessionStorage.getItem("userData")
                );
                if (
                  this.userDataObj.userData.showpopup &&
                  this.base64regex.test(this.userDataObj.userData.showpopup)
                ) {
                  var showpoup = atob(this.userDataObj.userData.showpopup);
                  if (showpoup == "false" && this.UserinfoPopup) {
                    delete this.userDataObj.userData.showpopup;
                  }
                  if (showpoup == "true" && !this.UserinfoPopup) {
                    delete this.userDataObj.userData.showpopup;
                  }
                } else {
                  delete this.userDataObj.userData.showpopup;
                }
                if (
                  this.userDataObj.userData.noOfPages &&
                  this.base64regex.test(this.userDataObj.userData.noOfPages)
                ) {
                  if (
                    Number(atob(this.userDataObj.userData.noOfPages)) !=
                    this.noOfPages
                  ) {
                    delete this.userDataObj.userData.noOfPages;
                  }
                } else {
                  delete this.userDataObj.userData.noOfPages;
                }
                if (
                  this.userDataObj.userData.filePath &&
                  this.base64regex.test(this.userDataObj.userData.filePath)
                ) {
                  if (
                    atob(this.userDataObj.userData.filePath) != this.filePath
                  ) {
                    delete this.userDataObj.userData.filePath;
                  }
                } else {
                  delete this.userDataObj.userData.filePath;
                }
                sessionStorage.setItem(
                  "userData",
                  JSON.stringify(this.userDataObj)
                );
              }
            }
            setTimeout(() => {
              this.scrHeight =
                $(window).height() - $(".foxit-header").outerHeight(true);
            }, 190);

            $(window).on("resize", () => {
              setTimeout(() => {
                this.scrHeight =
                  $(window).height() - $(".foxit-header").outerHeight(true);
              }, 190);
            });
            this.askForGDPRConsent = publicationpdfviewerData[3].askForGDPRConsent;
            //console.log(this.askForDownload);
            this.showSpinner = false;
            this.publicationDetail = publicationpdfviewerData[1];
            this.publicationGroupId = publicationpdfviewerData[2].idPublicationGroup;
            if (this.isShoppingCartEnabled || this.buyNowUrl) {
              this.mobileHeightConatiner = "mobileHeightConatiner";
            }
            this.sanitizeurl = this._nosanitizePipe.transform(
              this.singleGroup.adSnippetCode
            );
            //console.log(res);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  closeDialog() {
    var heght = $(".ad-container").outerHeight();
    $(".ad-container").animate({ bottom: "-360px" }, 300);
    setTimeout(() => {
      $(".slider").hide();
    }, 1200);
    if (
      this.permissions &&
      this.singleGroup.askForAds &&
      this.singleGroup.adTimer != null
    ) {
      //console.log(heght);
      setTimeout(() => {
        //$(".ad-container").css("height", heght + "px");
        $(".slider").show();
        $(".ad-container").animate({ bottom: "0px" }, 200);
      }, this.singleGroup.adTimer * 60 * 1000);
    }
  }
  trackBuyNow(buyNowUrl) {
    this.publicationService.sendClickCall(buyNowUrl);
  }
  downloadPub() {
    if (sessionStorage.getItem("userData") != null) {
      this.userDataObj = JSON.parse(sessionStorage.getItem("userData"));
      if (this.userDataObj.userData.hasOwnProperty("fullName")) {
        this._router.navigateByUrl(
          "/download/65be24394b4e1d111b4bcccf292d555d",
          {
            state: { previousurl: this._router.url },
          }
        );
      } else {
        this.getUserInfo = false;
      }
    } else {
      this.getUserInfo = false;
    }
  }
  public getUserData(data: any): void {
    //console.log(data);
    if (data.userData.hasOwnProperty("passcode")) {
      delete data.userData.passcode;
    }
    if (data.userData.hasOwnProperty("ipAddress")) {
      delete data.userData.ipAddress;
    }
    if (data.userData.hasOwnProperty("phoneno")) {
      delete data.userData.phoneno;
    }
    if (data.userData.hasOwnProperty("UsageType")) {
      delete data.userData.UsageType;
    }
    if (data.userData.hasOwnProperty("accessType")) {
      delete data.userData.accessType;
    }
    if (data.userData.hasOwnProperty("browser")) {
      delete data.userData.browser;
    }
    this.navigationMessage.state.data = data;
    if (data.userData.hasOwnProperty("emailaddress")) {
      data.userData.emailaddress = btoa(data.userData.emailaddress);
      this.getUserInfo = Object.keys(data.userData.emailaddress).length !== 0;
    }
    if (data.userData.hasOwnProperty("fullName")) {
      data.userData.fullName = btoa(data.userData.fullName);
    }
    if (data.userData.hasOwnProperty("organization")) {
      data.userData.organization = btoa(data.userData.organization);
    }
    if (data.userData.hasOwnProperty("RefGroupId")) {
      data.userData.RefGroupId = btoa(data.userData.RefGroupId);
    }
    if (data.userData.hasOwnProperty("customerId")) {
      data.userData.customerId = btoa(data.userData.customerId);
    }
    if (data.userData.hasOwnProperty("publicationTitle")) {
      data.userData.publicationTitle = btoa(data.userData.publicationTitle);
    }
    if (data.userData.hasOwnProperty("idPublication")) {
      data.userData.idPublication = btoa(data.userData.idPublication);
    }
    this.navigationMessage.state.data.userData.noOfPages = btoa(
      "" + this.noOfPages
    );

    this.navigationMessage.state.data.userData.filePath = btoa(this.filePath);

    if (this.UserinfoPopup) {
      this.navigationMessage.state.data.userData.showpopup = btoa("true");
    } else {
      this.navigationMessage.state.data.userData.showpopup = btoa("false");
    }
    if (this.askForGDPRConsent) {
      this.navigationMessage.state.data.userData.showGDPR = btoa("GDPRConsent");
    } else {
      this.navigationMessage.state.data.userData.showGDPR = btoa("notShow");
    }

    sessionStorage.setItem(
      "userData",
      JSON.stringify(this.navigationMessage.state.data)
    );
    //console.log(this.userDatainfo);
  }
  openDialog() {
    const dialogRef = this.dialog.open(PoweredByIwrapperComponent, {
      width: "650px",
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
  openpaymentDialoge() {
    if (this.isShoppingCartEnabled) {
      const dialogRefss = this.dialog.open(PaymentGatewayPopupComponent, {
        width: "650px",
        data: {
          publicationDetail: this.publicationDetail,
          publicationGroupId: this.publicationGroupId,
          idCollection: this.collection_code,
        },
        disableClose: true,
      });

      dialogRefss.afterClosed().subscribe((result) => {
        if (Object.keys(result).length !== 0 && typeof result !== "undefined") {
          this.isDemoCollection = result.demoPublicationStatus;
        }
        this.publicationService.sendClickCall("iWrapperShoppingCart");
      });
    }
  }
  openDownloadDialoge() {
    if (this.isShoppingCartEnabled) {
      const dialogRefss = this.dialog.open(DownloadPdfComponent, {
        width: "700px",
        data: {
          //publicationDetail: this.publicationDetail,
          //publicationGroupId: this.publicationGroupId,
          idCollection: this.collection_code,
          idPublication: this.publication_code,
        },
        disableClose: true,
      });

      dialogRefss.afterClosed().subscribe((result) => {});
    }
  }

  decryptData(cipherText: string, salt: string): any {
    try {
      // Decrypt the cipherText using the salt
      const bytes = CryptoJS.AES.decrypt(cipherText, salt);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      
      // Parse the decrypted JSON string back into an object
      return JSON.parse(decryptedData);
    } catch (e) {
      console.error('Error decrypting data:', e);
      return null;
    }
  }

}
